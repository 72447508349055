import { FC } from 'react';
// import Calculator from '../Calculator/Calculator';
// import CalculatorResult from '../CalculatorResult/CalculatorResult';

const CalculatorSection: FC = () => {
  // const fixedBlockRef = useRef<HTMLDivElement>(null);
  // const resultGapRef = useRef<HTMLDivElement>(null);
  // const configurationsRef = useRef<HTMLDivElement>(null);
  // const [isFixed, setIsFixed] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const fixedBlock = fixedBlockRef.current;
  //     const configurations = configurationsRef.current;

  //     if (!fixedBlock || !configurations) return;

  //     // const releasePoint =
  //     // configurations.offsetTop + configurations.offsetHeight - fixedBlock.offsetHeight;
  //     // const scrollPosition = window.scrollY || window.pageYOffset;

  //     // setIsFixed(fixedBlock.getBoundingClientRect().top < 0);

  //     // console.log(fixedBlock.getBoundingClientRect().top);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // eslint-disable-next-line no-lone-blocks
  {
    /* <div className="calculator-bg">
  <section className="media-about-us-localized">
    <div className="description-container bottom-text-container">
    <h2 className="media-title bottom-text">Медіа про нас </h2>
    <p className="media-subtitle bottom-text">
      About <br>ресурсів us
    </p>
  </div>
  <div className="medias-block">
    <div className="first-container medias-container">
      Containers here
    </div>
    <div className="second-container medias-container">
      Containers here
    </div>
  </div>
  </section>
</div> */
  }

  return (
    <div className="calculator-bg">
      <section className="media-about-us-localized">
        {/* <Calculator resultGapRef={resultGapRef} /> */}
      </section>
    </div>
  );
};

export default CalculatorSection;
