import { FC, useState } from 'react';
import './Header.scss';

const Header: FC = () => {
  const [isShowCalcResult] = useState(false);

  return (
    <header className={!isShowCalcResult ? 'hiden-calculator-result' : ''}>
      <div className="brand">
        <img src="assets/logo.svg" width="32" alt="Xedrum Logo" />
        <span
        // onClick={() => {
        //   setIsShowCalcResult((prev) => !prev);
        // }}
        >
          Xedrum
        </span>
      </div>

      {/* <div
        className={`calculator-result ${
          !isShowCalcResult ? 'hiden-calculator-result' : ''
        }`}
      >
        <div className="result-row">
          <div className="service">
            <div className="title">
              <img src="assets/calculator-icons/web.svg" alt="web" />
              <p>WEB</p>
            </div>
            <p className="price">$47,104</p>
          </div>
          <div className="service">
            <div className="title">
              <img src="assets/calculator-icons/mobile.svg" alt="mobile" />
              <p>MOBILE</p>
            </div>
            <p className="price">$47,104</p>
          </div>
          <div className="service">
            <div className="title">
              <img src="assets/calculator-icons/backend.svg" alt="backend" />
              <p>BACKEND</p>
            </div>
            <p className="price">$47,104</p>
          </div>
          <div className="service">
            <div className="title">
              <img src="assets/calculator-icons/design.svg" alt="design" />
              <p>DESIGN</p>
            </div>
            <p className="price">$47,104</p>
          </div>
        </div>
        <div className="result-row">
          <div className="total-cost">
            <p>Total cost:</p>
            <p>$47,104</p>
          </div>

          <div className="discuss">Discuss details</div>
        </div>
      </div> */}
    </header>
  );
};

export default Header;
