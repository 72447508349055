import { Route, Routes } from 'react-router-dom';
import MainLegacy from './components/MainLegacy/MainLegacy';
import './App.scss';

function App() {
  return (
    <Routes>
      <Route path="/" caseSensitive element={<MainLegacy />} />
    </Routes>
  );
}

export default App;
