import { FC } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import CalculatorSection from '../CalculatorSection/CalculatorSection';

const MainLegacy: FC = () => {
  return (
    <>
      <Header />

      <main className="kg-main-content">
        <div id="localized-page" className="localized-landing-page">
          <section className="scalable-section ua-head">
            <h1 className="head-title">
              <span className="line">We help businesses grow</span>
              <span className="line">Your growth, Our passion</span>
            </h1>
            <a
              href="https://calendly.com/xedrum"
              target="_blank"
              rel="noreferrer"
              className="careers-link"
            >
              <span>BOOK A CALL</span>
              <img
                className="arrow"
                loading="lazy"
                decoding="async"
                src="assets/arrow-right.svg"
                alt="Arrow right"
              />
            </a>
            <div className="scroll-sign">
              <img
                className="arrow"
                loading="lazy"
                decoding="async"
                src="assets/arrow-down.svg"
                alt="Arrow down"
              />
            </div>
          </section>
          <section className="about-company">
            <div className="description-block text-container">
              <h2 className="about-title text">Hello, we are — Xedrum</h2>
              <p className="about-description text">
                Welcome to our world of transformative IT solutions, where
                <strong>growth knows no bounds</strong> and success thrives
              </p>
            </div>
            <div className="numbers-block">
              <div className="number-container">
                <h4 className="number">+50</h4>
                <p className="number-subtitle">engineers</p>
              </div>
              <div className="number-container">
                <h4 className="number">11</h4>
                <p className="number-subtitle">active projects</p>
              </div>
              <div className="number-container">
                <h4 className="number">+25</h4>
                <p className="number-subtitle">happy clients</p>
              </div>
            </div>
          </section>
          <section className="page-scalable-section-container">
            <div className="common-pseudo-bg"></div>
            <div className="scalable-section our-mission">
              <div className="mission-description-container text-container">
                <h3 className="mission-title text">
                  We create innovative and technological products
                </h3>
                <p className="mission-description text">
                  Our mission is to be the platform for positive change for our
                  partners and clients, offering modern technological solutions.
                  At Xedrum, we help innovate, creating applications for various
                  industries, inspiring development and advancement
                </p>
              </div>
              <div className="mission-animation-container">
                <div className="image-container first-img">
                  <img
                    className="mission-image"
                    loading="lazy"
                    decoding="async"
                    src="assets/cases/hyperjar.png"
                    alt="Hyperjar"
                  />
                </div>
                <div className="image-container second-img">
                  <img
                    className="mission-image"
                    loading="lazy"
                    decoding="async"
                    src="assets/cases/sdk.png"
                    alt="Sdk"
                  />
                </div>
                <div className="image-container third-img">
                  <img
                    className="mission-image"
                    loading="lazy"
                    decoding="async"
                    src="assets/cases/crm.png"
                    alt="Crm"
                  />
                </div>
              </div>
              <div className="mission-animation-slider">
                <div className="slider-image-container">
                  <img
                    className="slider-image"
                    loading="lazy"
                    decoding="async"
                    src="assets/cases/hyperjar.png"
                    alt="Hyperjar"
                  />
                </div>
                <div className="slider-image-container">
                  <img
                    className="slider-image"
                    loading="lazy"
                    decoding="async"
                    src="assets/cases/sdk.png"
                    alt="Sdk"
                  />
                </div>
                <div className="slider-image-container">
                  <img
                    className="slider-image"
                    loading="lazy"
                    decoding="async"
                    src="assets/cases/crm.png"
                    alt="Crm"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="localized-values">
            <div className="values-container content-container">
              <div className="values-scroll">
                <div className="values-list">
                  <div className="values-item intro-item">
                    <div className="value-content text-container">
                      <h2 className="value-title text">Our values</h2>
                      <p className="value-subtitle text">
                        What we believe in and what we are guided by at work
                        meetings and at the weekend
                      </p>
                    </div>
                  </div>
                  <div className="values-item">
                    <div className="value-content text-container">
                      <img
                        className="icon"
                        src="assets/values/lighthouse-100.png"
                        alt="lighthouse"
                      />
                      <h2 className="value-title text">
                        We always discover light
                      </h2>
                      <p className="value-subtitle text">
                        We always discover light, even on the darkest nights,
                        thus the term "blocker" doesn't apply to us
                      </p>
                    </div>
                  </div>
                  <div className="values-item widest-item">
                    <div className="value-content horizontal-text-container">
                      <img
                        className="icon"
                        src="assets/values/love-100.png"
                        alt="love"
                      />
                      <h2 className="value-title horizontal-text">
                        Our clients are our family
                      </h2>
                      <p className="value-subtitle horizontal-text">
                        Since our clients are members of our family, we treat
                        them with the same love and care that we provide to our
                        teammates. As a result, we provide only the highest
                        caliber of work for our clients
                      </p>
                    </div>
                  </div>
                  <div className="values-item widest-item">
                    <div className="value-content horizontal-text-container">
                      <img
                        className="icon"
                        src="assets/values/explosion-100.png"
                        alt="success"
                      />
                      <h2 className="value-title horizontal-text">
                        A fusion of creativity & science
                      </h2>
                      <p className="value-subtitle horizontal-text">
                        A fusion of creativity and science allows us to build
                        everything for our clients with all the standards, but
                        we can also add super extraordinary solutions and make
                        the client's product unique
                      </p>
                    </div>
                  </div>
                  <div className="values-item">
                    <div className="value-content horizontal-text-container">
                      <img
                        className="icon"
                        src="assets/values/trophy-100.png"
                        alt="trophy"
                      />
                      <h2 className="value-title horizontal-text">
                        Xedrum = result
                      </h2>
                      <p className="value-subtitle horizontal-text">
                        Xedrum and result are equivalent, in the sense that
                        someone arrives with a concept and departs with an
                        unrealistic outcome
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="customers">
            <div className="description-container bottom-text-container">
              <h3 className="customers-subtitle bottom-text">
                We are proud to work with clients from Great Britain, USA,
                Switzerland, Germany, Canada, Sweden, Saudi Arabia and other
                countries
              </h3>
            </div>
            <div
              className="sk-ww-linkedin-page-post"
              data-embed-id="225513"
            ></div>
          </section>
          <section className="page-scalable-section-container">
            <div className="common-pseudo-bg"></div>
            <div className="our-team-localized">
              <div className="description-container bottom-text-container">
                <div className="titles-block">
                  <h2 className="team-title bottom-text">Meet,</h2>
                  <h3 className="team-subtitle bottom-text">our team</h3>
                </div>
                <p className="team-description bottom-text">
                  Everything we have accomplished so far and everything we have
                  ahead of us is made possible by our amazing team. Our team has
                  grown to <span style={{ color: '#53b36f' }}>50 members</span>,
                  and we are still expanding all of the company's departments
                </p>
              </div>
              <div className="team-members-container">
                <img
                  className="all-team"
                  src="assets/team/team.jpeg"
                  alt="team"
                />
              </div>
              <div className="team-members-container">
                <div className="member-container">
                  <div
                    className="person-photo"
                    style={{
                      backgroundImage: 'url(assets/team/oleksandr.jpeg)',
                    }}
                  ></div>
                  <div className="person-info bottom-text-container">
                    <p className="person-name bottom-text">Oleksandr Khomych</p>
                    <p className="person-position bottom-text">Head of PMO</p>
                  </div>
                </div>
                <div className="member-container">
                  <div
                    className="person-photo"
                    style={{
                      backgroundImage: 'url(assets/team/alina.jpeg)',
                    }}
                  ></div>
                  <div className="person-info bottom-text-container">
                    <p className="person-name bottom-text">Alina Kuzicheva</p>
                    <p className="person-position bottom-text">
                      Business Manager
                    </p>
                  </div>
                </div>
                <div className="member-container">
                  <div
                    className="person-photo"
                    style={{
                      backgroundImage: 'url(assets/team/nazar.jpeg)',
                    }}
                  ></div>
                  <div className="person-info bottom-text-container">
                    <p className="person-name bottom-text">Nazar Hrytsiv</p>
                    <p className="person-position bottom-text">
                      Business Manager
                    </p>
                  </div>
                </div>
                <div className="member-container">
                  <div
                    className="person-photo"
                    style={{
                      backgroundImage: 'url(assets/team/lilia.jpeg)',
                    }}
                  ></div>
                  <div className="person-info bottom-text-container">
                    <p className="person-name bottom-text">Liliana Moisei</p>
                    <p className="person-position bottom-text">
                      Partnership Manager
                    </p>
                  </div>
                </div>
                <div className="member-container">
                  <div
                    className="person-photo"
                    style={{
                      backgroundImage: 'url(assets/team/danylo.jpeg)',
                    }}
                  ></div>
                  <div className="person-info bottom-text-container">
                    <p className="person-name bottom-text">Danylo Melnychuk</p>
                    <p className="person-position bottom-text">Co-Founder</p>
                  </div>
                </div>
                <div className="member-container">
                  <div
                    className="person-photo"
                    style={{
                      backgroundImage: 'url(assets/team/bohdan.jpeg)',
                    }}
                  ></div>
                  <div className="person-info bottom-text-container">
                    <p className="person-name bottom-text">Bohdan Krupa</p>
                    <p className="person-position bottom-text">Co-Founder</p>
                  </div>
                </div>
              </div>
              <div className="cta-block team-cta-block bottom-text-container">
                <p className="cta-description bottom-text">
                  Are you curious about a question?
                  <br />
                  Let's talk about it!
                </p>
                <a
                  href="https://calendly.com/xedrum"
                  target="_blank"
                  rel="noreferrer"
                  className="book-a-call-2"
                >
                  Plan a call
                </a>
              </div>
            </div>
          </section>

          <CalculatorSection />

          <section className="faq-section" data-no-turbolink="true">
            <div className="description-container bottom-text-container">
              <h2 className="faq-title bottom-text">FAQ</h2>
            </div>
            <div className="faqs-container">
              <div className="faq-content">
                <div className="faq faq-question">
                  <div
                    className="question-container faq-text-container"
                    itemScope
                    itemProp="mainEntity"
                    itemType="https://schema.org/Question"
                  >
                    <div className="question-controls-container">
                      <div className="buttons-container">
                        <button
                          className="faq-btn more-btn visibility-btn"
                          type="button"
                        >
                          <img
                            className="btn-icon more-icon"
                            src="assets/plus-sign.svg"
                            alt="Plus sign"
                          />
                        </button>
                        <button
                          className="faq-btn less-btn visibility-btn"
                          type="button"
                        >
                          <img
                            className="btn-icon less-icon"
                            src="assets/minus-sign.svg"
                            alt="Minus sign"
                          />
                        </button>
                      </div>
                      <h3 className="question" itemProp="name">
                        Are all of Xedrum's customers happy?
                      </h3>
                    </div>
                    <p
                      className="answer bottom-text"
                      itemScope
                      itemProp="acceptedAnswer"
                      itemType="https://schema.org/Answer"
                    >
                      Yes, making sure our clients are satisfied is our top
                      priority. Making them always happy and satisfied with our
                      work is our main goal. We are excellent at completing this
                      task
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-content">
                <div className="faq faq-question">
                  <div
                    className="question-container faq-text-container"
                    itemScope
                    itemProp="mainEntity"
                    itemType="https://schema.org/Question"
                  >
                    <div className="question-controls-container">
                      <div className="buttons-container">
                        <button
                          className="faq-btn more-btn visibility-btn"
                          type="button"
                        >
                          <img
                            className="btn-icon more-icon"
                            src="assets/plus-sign.svg"
                            alt="Plus sign"
                          />
                        </button>
                        <button
                          className="faq-btn less-btn visibility-btn"
                          type="button"
                        >
                          <img
                            className="btn-icon less-icon"
                            src="assets/minus-sign.svg"
                            alt="Minus sign"
                          />
                        </button>
                      </div>
                      <h3 className="question" itemProp="name">
                        Does Xedrum provide dedicated team services or
                        outstaffing?
                      </h3>
                    </div>
                    <p
                      className="answer bottom-text"
                      itemScope
                      itemProp="acceptedAnswer"
                      itemType="https://schema.org/Answer"
                    >
                      Yes, Xedrum gives you the chance to work directly with our
                      extremely talented developers. This frees up clients to
                      concentrate only on delivering their goods. Our committed
                      developers have all the information, expertise, and
                      abilities needed to assist you in completing difficult and
                      urgent project tasks quickly, effectively, and flawlessly
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-content">
                <div className="faq faq-question">
                  <div
                    className="question-container faq-text-container"
                    itemScope
                    itemProp="mainEntity"
                    itemType="https://schema.org/Question"
                  >
                    <div className="question-controls-container">
                      <div className="buttons-container">
                        <button
                          className="faq-btn more-btn visibility-btn"
                          type="button"
                        >
                          <img
                            className="btn-icon more-icon"
                            src="assets/plus-sign.svg"
                            alt="Plus sign"
                          />
                        </button>
                        <button
                          className="faq-btn less-btn visibility-btn"
                          type="button"
                        >
                          <img
                            className="btn-icon less-icon"
                            src="assets/minus-sign.svg"
                            alt="Minus sign"
                          />
                        </button>
                      </div>
                      <h3 className="question" itemProp="name">
                        Why Xedrum?
                      </h3>
                    </div>
                    <p
                      className="answer bottom-text"
                      itemScope
                      itemProp="acceptedAnswer"
                      itemType="https://schema.org/Answer"
                    >
                      We are confident Xedrum is the best option since we have
                      individuals who share the mindset of the organization. Our
                      customer satisfaction is our priority, so every team
                      member working on a project is prepared to invest all of
                      their time and energy into making sure the result will be
                      profitable for the client
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-content">
                <div className="faq faq-question">
                  <div
                    className="question-container faq-text-container"
                    itemScope
                    itemProp="mainEntity"
                    itemType="https://schema.org/Question"
                  >
                    <div className="question-controls-container">
                      <div className="buttons-container">
                        <button
                          className="faq-btn more-btn visibility-btn"
                          type="button"
                        >
                          <img
                            className="btn-icon more-icon"
                            src="assets/plus-sign.svg"
                            alt="Plus sign"
                          />
                        </button>
                        <button
                          className="faq-btn less-btn visibility-btn"
                          type="button"
                        >
                          <img
                            className="btn-icon less-icon"
                            src="assets/minus-sign.svg"
                            alt="Minus sign"
                          />
                        </button>
                      </div>
                      <h3 className="question" itemProp="name">
                        What do our customers say about us?
                      </h3>
                    </div>
                    <p
                      className="answer bottom-text"
                      itemScope
                      itemProp="acceptedAnswer"
                      itemType="https://schema.org/Answer"
                    >
                      Good feedback from our clients emphasizes our attention to
                      detail, flexibility, and capacity to take on additional
                      work. They also mention how good our services are
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-content">
                <div className="faq faq-question">
                  <div
                    className="question-container faq-text-container"
                    itemScope
                    itemProp="mainEntity"
                    itemType="https://schema.org/Question"
                  >
                    <div className="question-controls-container">
                      <div className="buttons-container">
                        <button
                          className="faq-btn more-btn visibility-btn"
                          type="button"
                        >
                          <img
                            className="btn-icon more-icon"
                            src="assets/plus-sign.svg"
                            alt="Plus sign"
                          />
                        </button>
                        <button
                          className="faq-btn less-btn visibility-btn"
                          type="button"
                        >
                          <img
                            className="btn-icon less-icon"
                            src="assets/minus-sign.svg"
                            alt="Minus sign"
                          />
                        </button>
                      </div>
                      <h3 className="question" itemProp="name">
                        Which other services does Xedrum provide?
                      </h3>
                    </div>
                    <p
                      className="answer bottom-text"
                      itemScope
                      itemProp="acceptedAnswer"
                      itemType="https://schema.org/Answer"
                    >
                      Apart from our primary services, Xedrum also offers
                      continuous assistance and upkeep for the projects we
                      undertake. We ensure our clients' projects are successful
                      in the long run by providing round-the-clock technical
                      support to handle any problems or queries that may come up
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default MainLegacy;
