import { FC } from 'react';

const Footer: FC = () => {
  return (
    <>
      <footer className="ua-footer">
        <div className="contact-info">
          <div className="ua-contacts contacts">
            <h4 className="location-name">Lviv 🇺🇦</h4>
            <div className="contacts-container">
              <a
                className="email-link contact-link"
                href="mailto:contact@xedrum.com"
              >
                contact@xedrum.com
              </a>
              <a className="phone-link contact-link" href="tel:+380982228262">
                +38 098 222 82 62
              </a>
              <a className="phone-link contact-link" href="tel:+380966651469">
                +38 096 665 14 69
              </a>
            </div>
          </div>
        </div>
        <div className="social-networks">
          <a
            className="network-link"
            target="_blank"
            rel="noreferrer"
            href="https://clutch.co/profile/xedrum"
          >
            Clutch
          </a>
          <a
            className="network-link"
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/xedrum"
          >
            Linkedin
          </a>
          <a
            className="network-link"
            target="_blank"
            rel="noreferrer"
            href="https://www.goodfirms.co/company/xedrum"
          >
            GoodFirms
          </a>
        </div>
      </footer>
      <div className="awards">
        <img
          src="assets/clutch/clutch_1000_2022_award.svg"
          alt="Clutch top 1000 companies 2022"
        />
        <img
          src="assets/clutch/top_clutch.co_development_company_ukraine_2021_award.svg"
          alt="Clutch top development company 2021"
        />
        <img
          src="assets/clutch/top_clutch.co_managed_service_provider_ukraine_2023.svg"
          alt="Clutch top managed company 2023"
        />
      </div>
    </>
  );
};

export default Footer;
